.noti-div-inner {
    padding: 1% 0%;
    border-bottom: 1px solid rgb(0 0 0 / 12%);
}
.noti-div-inner .noti-date {
    font-weight: 600;
}

.noti-div-inner .noti-dis{
    font-size: smaller;
    color: #000;
    font-weight: 600;
}
.noti-not-found {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
.noti-fancy-loader {
    height: 40px;
    animation: fancy-loading 1s linear infinite alternate;
    background-color: aqua;
    border-radius: 8px;
    position: relative;
}
.noti-fancy-loader::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    -webkit-animation: fancyinner 2s infinite;
    animation: shimmer 2s infinite;
    content: "";
}
@keyframes fancyinner {
    100% {
        transform: translateX(100%);
    }
}
@keyframes fancy-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}
.noti-div .notification-loader {
    overflow: hidden;
}
.noti-fancy-loader {
    overflow: hidden;
}
.view-btn-noti{
    color: #fff;
}
