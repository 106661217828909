@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01rem;
  color: #6e6b7b;
  background-color: #f8f8f8;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.page-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.worlddd .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  overflow: "unset";
}

/* .custom-th-width .brFloq {
  width: 135px !important;
}

.custom-th-gp-width .brFloq {
  width: 155px !important;
} */

/* .hideDiv .sc-eDPEul.hGcwUo {
  position: static;
} */

.my-special-class div:first-child div:nth-child(3) {
  position: static;
}

.custom-th-gp-width .rdt_TableCol_Sortable>div {
  width: 155px !important;
}

.custom-th-ga-width .rdt_TableCol_Sortable>div {
  width: 155px !important;
}
.replace-guard .sc-eDPEul.hGcwUo {
  position: static;
}