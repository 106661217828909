/* Drawer Button CSS start */

.DrawerInnerButton {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ButtonIcon {
  margin-right: 10px;
}

.ButtonTitle {
  font-size: 17px;
  font-weight: 500;
}

.ButtonLink:hover,
.ButtonLink.open {
  background: whitesmoke;
}

.ButtonLink {
  border-radius: 6px;
  margin-bottom: 7px;
  padding: 10px 10px !important;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.dropdownCol .linkicon {
  width: 15px;
  height: 15px;
}
.DrawerInnerButton .ButtonIcon {
  width: 17px;
  height: 17px;
}
.DrawerInnerButton i {
  font-size: 17px;
}
.menu-toggle-icon {
  transform: rotateX(0);
  transition: -webkit-transform 0.2s ease, transform 0.2s ease;
  -webkit-transform: rotateX(0);
  -webkit-transition: -webkit-transform 0.2s ease, transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
}

.ButtonLink.open .menu-toggle-icon {
  transform: rotateX(180deg);
  transition: -webkit-transform 0.2s ease, transform 0.2s ease;
  -webkit-transform: rotateX(180deg);
  -webkit-transition: -webkit-transform 0.2s ease, transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
}

.DrawerButton {
  padding-left: 15px;
  padding-right: 15px;
}
.verify-otp-text {
  font-size: 15px;
  text-align: start !important;
}

/* Drawer Button CSS end */
.width300px {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.dropdown-notification .badge.badge-up {
  position: absolute;
  top: 3px;
  min-width: auto;
  min-height: auto;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 10px;
  right: 0px;
}
.react-datepicker__tab-loop {
  z-index: 2 !important;
}
.d-flex .btn-outline-primary {
  max-height: 38px;
}
.company-figures-company .badge i {
  font-size: 12px;
}
.company-figures-company .badge {
  width: 27px;
  height: 27px;
}
.company-figures-company {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.css-1nmdiq5-menu {
  z-index: 2 !important;
}

/* 11-9-24 */
.contract-shedule-table-one .sc-fqkvVR {
  width: 170px !important;
}
