.header-navbar {
  min-height: 4rem;
}

.header-navbar.floating-nav {
  margin: 0;
  border-radius: 0.5rem;
  background: #fff;
  position: fixed;
  width: calc(100% - calc(2.2rem * 2) - 199px);
  z-index: 9;
  top: 0;
  right: 0;
}

.header-navbar.navbar-shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 5%);
}

.navbar.navbar-col {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar.navbar-col:before {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0.95) 44%,
    rgba(248, 248, 248, 0.46) 73%,
    rgba(255, 255, 255, 0)
  );
  width: 100%;
  position: absolute;
  top: -40px;
  height: 100%;
  left: 0;
  z-index: -1;
  right: 0;
}

.menu-toggle {
  padding-left: 1rem;
  display: none;
}

.round {
  border-radius: 50%;
}

.user-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}
.user-status {
  line-height: 15px;
  color: #6e6b7b;
  font-size: 12px;
}

.nav-link.nav-link-custom {
  color: #212529;
  cursor: pointer;
  display: flex;
}

.nav-link .user-nav {
  flex-direction: column;
  align-items: flex-end;
  float: left;
  margin-right: 0.8rem;
  justify-content: center;
}

.nav-link.nav-link-custom:hover {
  color: #212529;
}

.right-section {
  margin-left: auto;
  display: flex;
}

.dropdown-menu.dropdowncol {
  display: block;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 0.95rem;
  cursor: pointer;
}

.dropdown .dropdown-menu::before {
  display: none;
}

.dropdown-menu .dropdown-item {
  padding: 0.5rem 10px;
}

.svg-icon {
  margin-right: 10px;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown-toggle.btn-success,
.dropdown-toggle.btn-success:hover,
.dropdown-toggle.btn-success:focus {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background: linear-gradient(
    140deg,
    #263a96,
    rgba(113, 133, 221, 0.7)
  ) !important;
  color: #fff !important;
}

.dropdown {
  display: flex;

}

.dropdown-menu{
  /* max-width: 10rem; */
  right: 0 !important;
  left: auto !important;
}

.dropdown-notification .nav-link {
  padding: 10px 6px;
}

.dropdown-menu-media.dropdown-menu {
  min-width: 400px;
  padding: 0;
}

.dropdown-menu-media .dropdown-header {
  padding: 1.22rem 1.28rem;
  border-bottom: 1px solid #ebe9f1;
}
.dropdown-menu-media .notification-title {
  font-size: 1.2rem;
  color: #5e5873;
  white-space: nowrap;
  font-weight: 500;
}
.dropdown-menu-media .dropdown-menu-footer {
  padding: 1.28rem;
  border-top: 1px solid #ebe9f1;
  margin-top: -1px;
}

.dropdown-menu-media .media-list .list-item {
  padding: 0.7rem 1.28rem;
  border: none;
  border-bottom: 1px solid #ebe9f1;
  flex-grow: 1;
}
.dropdown-menu-media .media-list .list-item:hover {
  background: #f8f8f8;
}

.dropdown-menu-media .notification-img {
  border-radius: 50%;
}

.dropdown-menu-media .media-heading {
  color: #6e6b7b;
  line-height: 1;
}
.dropdown-menu-media .new-message {
  color: #6e6b7b;
  line-height: 1;
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}
.dropdown-menu-media .notification-text {
  font-size: smaller;
  color: #000;
  font-weight: 600;
}
.dropdown-menu-media .avatar .avatar-content {
  font-size: 0.75rem;
  font-weight: 600;
  width: 32px;
  height: 32px;
}
.scrollbar-container.media-list {
  max-height: 21rem;
  overflow-x: overlay;
}

.badge.badge-up {
  position: absolute;
  top: 7px;
  min-width: 1.2rem;
  min-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  line-height: 0.68;
  right: -4px;
}
.dropdown-notification i {
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .menu-toggle {
    display: block;
  }

  .header-navbar.floating-nav {
    width: calc(100% - 2.4rem);
  }

  .header-navbar.floating-nav {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  .content-wrapper {
    padding: calc(2.2rem - 1rem) 1.2rem 0;
  }
  .card {
    margin-bottom: 1.3rem;
  }
}

@media only screen and (max-width: 767px) {
  .user-name {
    font-size: 16px;
  }
  .dropdown-notification i {
    font-size: 18px;
  }
  .scrollbar-container.media-list {
    max-height: 13rem;
    overflow-x: overlay;
  }
  .dropdown-menu-media.dropdown-menu {
    min-width: 270px;
    transform: translate3d(-230px, 60px, 0) !important;
  }
}

@media only screen and (max-width: 990px) {
  .dropdown-menu-media.dropdown-menu {
    transform: translate3d(-37vw, 50px, 0) !important;
    inset: auto auto auto auto !important;
  }
}

@media only screen and (max-width: 575px) {
  .dropdown-menu-media.dropdown-menu {
    transform: translate3d(-230px, 60px, 0) !important;
  }
  .scrollbar-container.media-list {
    max-height: 12rem;
    overflow-x: overlay;
  }
}
@media only screen and (max-width: 389px) {
  .dropdown-menu-media.dropdown-menu {
    transform: translate3d(-170px, 60px, 0) !important;
    min-width: 210px;
  }
  .dropdown-menu-footer button.btn {
    font-size: 12px;
  }
  .scrollbar-container.media-list {
    max-height: 9rem;
    overflow-x: overlay;
  }
}


.ml-10p{
  margin-left: 10px;
}
/* Added By Kushal */
.noti-loader-main{
  height: fit-content;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 51px;
  margin: 2% 3%;
  border-radius: 8px;
}
.noti-date{
  font-size: 12px;
  font-weight: 600;
  color: #b9b9c3;
}
.no-found-noti{
  text-align: center;
  margin: 6%;
  font-weight: 600;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}