.customLoader {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #00000050;
    align-items: center;
    justify-content: center;
    z-index: 5000;
    top: 0;
    left: 0;
  }
  
  .loaderCard {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    padding: 1.25em;
    border: none;
    border-radius: 5px;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
  }