.MainMenu {
  position: fixed;
  display: table-cell;
  height: 100%;
  overflow-x: hidden;
  width: 269px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  top: 0;
  overflow-y: auto;
}
*::-webkit-scrollbar-thumb {
  background-color: #bcbaba;
  border-radius: 20px;
}
*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #bcbaba50;
}

.NavbarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.Logo {
  max-width: 160px;
}

.link {
  padding: 10px 15px;
  background: #fff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
  cursor: pointer;
  color: #6e6b7b;
}

.link.active:hover {
  color: #fff;
}
.link.active {
  background: linear-gradient(140deg, #263a96, rgba(113, 133, 221, 0.7));
  color: #fff;
}

.linkicon {
  margin-right: 10px;
  display: flex;
  font-size: 13px;
}

.link:hover {
  background-color: whitesmoke;
  color: #6e6b7b;
}

.CloseIcon {
  display: none;
}

.pl_10 {
  padding-left: 10px;
}

@media only screen and (max-width: 1200px) {
  .MainMenu {
    width: 0;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 999;
  }

  .MainMenu.hide-sidebar {
    width: 260px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .CloseIcon {
    display: block;
  }
}
