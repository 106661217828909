@import url("./assets/FontawesomePro/css/all.css");

a,
a:hover {
  text-decoration: none;
  color: #263a96;
}
:focus-visible {
  outline: none;
}

/*--------------------------Filled buttons--------------------------*/

/*Filled primary button*/
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #263a96;
}

.btn-primary:active:focus,
.btn-primary:focus {
  box-shadow: none;
}
.btn-primary:focus,
.btn-primary:hover,
.btn-primary {
  background: #263a96;
  border: #263a96;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}

/*Filled primary button*/

/*Filled secondary button*/
.btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #82868b;
}

.btn-secondary:active:focus,
.btn-secondary:focus {
  box-shadow: none;
}

.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary {
  background: #82868b;
  border: #82868b;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*Filled secondary button*/

/*Filled btn-success button*/
.btn-success:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #28c76f;
}

.btn-success:active:focus,
.btn-success:focus {
  box-shadow: none;
}

.btn-success:focus,
.btn-success:hover,
.btn-success {
  background: #28c76f;
  border: #28c76f;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*Filled btn-success button*/

/*Filled btn-warning button*/
.btn-warning:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #ff9f43;
}

.btn-warning:active:focus,
.btn-warning:focus {
  box-shadow: none;
}

.btn-warning:focus,
.btn-warning:hover,
.btn-warning {
  background: #ff9f43;
  border: #ff9f43;
  color: #fff;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*Filled btn-warning button*/

/*Filled btn-danger button*/
.btn-danger:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #ea5455;
}

.btn-danger:active:focus,
.btn-danger:focus {
  box-shadow: none;
}

.btn-danger:focus,
.btn-danger:hover,
.btn-danger {
  background: #ea5455;
  border: #ea5455;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*Filled btn-danger button*/

/*Filled btn-info button*/
.btn-info:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #00cfe8;
}

.btn-info:active:focus,
.btn-info:focus {
  box-shadow: none;
}

.btn-info:focus,
.btn-info:hover,
.btn-info {
  background: #00cfe8;
  border: #00cfe8;
  color: #fff;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*Filled btn-info button*/

/*Filled btn-dark button*/
.btn-dark:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #4b4b4b;
}

.btn-dark:active:focus,
.btn-dark:focus {
  box-shadow: none;
}

.btn-dark:focus,
.btn-dark:hover,
.btn-dark {
  background: #4b4b4b;
  border: #4b4b4b;
  color: #fff;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*Filled btn-dark button*/

/*--------------------------Filled buttons--------------------------*/

/*--------------------------Outline buttons--------------------------*/

/*outline primary button*/
.btn-outline-primary:active:focus,
.btn-outline-primary:focus {
  box-shadow: none;
}
/* .btn-outline-primary:not(:disabled):not(.disabled):hover {
  background-color: rgba(38, 58, 150, 0.2);
} */
/* .btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):focus {
  background-color: rgba(38, 58, 150, 0.2);
} */
.btn-outline-primary:hover:not(.disabled):not(:disabled) {
  color: #263a96;
}
.btn-outline-primary:active,
.btn-outline-primary:hover,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: transparent;
  border-color: #263a96;
  color: #263a96;
}

.btn-outline-primary {
  color: #263a96;
  border-color: #263a96;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline primary button*/

/*outline secondary button*/
.btn-outline-secondary:active:focus,
.btn-outline-secondary:focus {
  box-shadow: none;
}
/* .btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  background-color: rgba(130, 134, 139, 0.2);
} */
.btn-outline-secondary:hover:not(.disabled):not(:disabled) {
  color: #82868b;
}
.btn-outline-secondary:active,
.btn-outline-secondary:hover,
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  background-color: transparent;
  border-color: #82868b;
  color: #82868b;
}
.btn-outline-secondary {
  color: #82868b;
  border-color: #82868b;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline secondary button*/

/*outline success button*/
.btn-outline-success:active:focus,
.btn-outline-success:focus {
  box-shadow: none;
}
/* .btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled):focus {
  background-color: rgba(40, 199, 111, 0.2);
} */
.btn-outline-success:hover:not(.disabled):not(:disabled) {
  color: #28c76f;
}
.btn-outline-success:active,
.btn-outline-success:hover,
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  border-color: #28c76f;
  color: #28c76f;
}
.btn-outline-success {
  color: #28c76f;
  border-color: #28c76f;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline success button*/

/*outline warning button*/
.btn-outline-warning:active:focus,
.btn-outline-warning:focus {
  box-shadow: none;
}
/* .btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled):focus {
  background-color: rgba(255, 159, 67, 0.2);
} */
.btn-outline-warning:hover:not(.disabled):not(:disabled) {
  color: #ff9f43;
}
.btn-outline-warning:active,
.btn-outline-warning:hover,
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  border-color: #ff9f43;
  color: #ff9f43;
}
.btn-outline-warning {
  color: #ff9f43;
  border-color: #ff9f43;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline warning button*/

/*outline danger button*/
.btn-outline-danger:active:focus,
.btn-outline-danger:focus {
  box-shadow: none;
}
/* .btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled):focus {
  background-color: rgba(234, 84, 85, 0.2);
} */
.btn-outline-danger:hover:not(.disabled):not(:disabled) {
  color: #ea5455;
}
.btn-outline-danger:active,
.btn-outline-danger:hover,
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  border-color: #ea5455;
  color: #ea5455;
}
.btn-outline-danger {
  color: #ea5455;
  border-color: #ea5455;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline danger button*/

/*outline info button*/
.btn-outline-info:active:focus,
.btn-outline-info:focus {
  box-shadow: none;
}
/* .btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled):focus {
  background-color: rgba(0, 207, 232, 0.2);
} */
.btn-outline-info:hover:not(.disabled):not(:disabled) {
  color: #00cfe8;
}
.btn-outline-info:active,
.btn-outline-info:hover,
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  border-color: #00cfe8;
  color: #00cfe8;
}
.btn-outline-info {
  color: #00cfe8;
  border-color: #00cfe8;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline info button*/

/*outline dark button*/
.btn-outline-dark:active:focus,
.btn-outline-dark:focus {
  box-shadow: none;
}
/* .btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled):focus {
  background-color: rgba(75, 75, 75, 0.2);
} */
.btn-outline-dark:hover:not(.disabled):not(:disabled) {
  color: #4b4b4b;
}
.btn-outline-dark:active,
.btn-outline-dark:hover,
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  background-color: transparent;
  border-color: #4b4b4b;
  color: #4b4b4b;
}
.btn-outline-dark {
  color: #4b4b4b;
  border-color: #4b4b4b;
  padding: 0.5rem 1.3rem;
  border-radius: 0.358rem;
}
/*outline dark button*/

/*--------------------------Outline buttons--------------------------*/

/*--------------------------Icons buttons--------------------------*/

/*Icon button*/
.btn-flat-success:hover:not(.disabled):not(:disabled) {
  background-color: rgba(40, 199, 111, 0.12);
}
.btn-flat-success:active:focus,
.btn-flat-success:focus {
  box-shadow: none;
}
.btn-flat-success:hover,
.btn-flat-success {
  color: #28c76f;
}
.button-icon {
  margin-right: 5px;
  font-size: 14px;
}

/*--------------------------Icons buttons--------------------------*/

/*--------------------------Icon Only buttons--------------------------*/

/*Icon Only*/
.btn.btn-btn-icon {
  padding: 0.5rem 0.66rem;
}
/*Icon Only*/

/*--------------------------Icon Only buttons--------------------------*/

/*--------------------------Switch buttons--------------------------*/

.demo-inline-spacing > * {
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}
.form-switch {
  padding-left: 3.5rem;
}
.form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-input:active {
  filter: brightness(100%);
}
.form-check-input:checked {
  background-color: #263a96;
  border-color: #263a96;
}

.form-check-input:focus {
  box-shadow: none;
}
.form-switch .form-check-input {
  border: none;
  border-radius: 3rem;
  height: 1.4rem;
  width: 3rem;
  margin-left: -3.5em;
}
.form-switch .form-check-input,
.form-switch .form-check-input:focus,
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Ccircle id='path-1' cx='8' cy='8' r='8'%3E%3C/circle%3E%3Cfilter x='-40.6%25' y='-21.9%25' width='168.8%25' height='168.8%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='switches-dot' transform='translate(5.000000, 5.000000)' fill-rule='nonzero'%3E%3Cg id='Oval'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.form-switch .form-check-label {
  color: #6e6b7b;
  font-size: 0.95rem;
  line-height: 1.7rem;
}
/*--------------------------Switch buttons--------------------------*/

/*--------------------------Switch Color buttons--------------------------*/

/*form-check-secondary*/
.form-check-secondary .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-secondary .form-check-input:checked {
  background-color: #82868b;
  border-color: #82868b;
}
/*form-check-secondary*/

/*form-check-success*/
.form-check-success .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-success .form-check-input:checked {
  background-color: #28c76f;
  border-color: #28c76f;
}
/*form-check-success*/

/*form-check-danger*/
.form-check-danger .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-danger .form-check-input:checked {
  background-color: #ea5455;
  border-color: #ea5455;
}
/*form-check-danger*/

/*form-check-warning*/
.form-check-warning .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-warning .form-check-input:checked {
  background-color: #ff9f43;
  border-color: #ff9f43;
}
/*form-check-warning*/

/*form-check-info*/
.form-check-info .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-info .form-check-input:checked {
  background-color: #00cfe8;
  border-color: #00cfe8;
}
/*form-check-info*/

/*form-check-dark*/
.form-check-dark .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-check-dark .form-check-input:checked {
  background-color: #4b4b4b;
}
/*form-check-dark*/

/*--------------------------Switch Color buttons--------------------------*/

/*--------------------------Radio Color buttons--------------------------*/
.radio-button .form-check-input:focus {
  border-color: #263a96;
}
.radio-button .form-check-input:not(:checked) {
  background-color: #fff;
}

.form-check .form-check-input:checked[type="radio"] {
  background-image: none;
}
.form-check .form-check-label {
  color: #6e6b7b;
  font-size: 0.95rem;
  line-height: 1.5rem;
}

/*--------------------------Radio Color buttons--------------------------*/

/*--------------------------form wizard--------------------------*/
.bs-stepper {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(34 41 47 / 10%) 0px 4px 24px 0px;
  border-radius: 0.5rem;
}
.bs-stepper .bs-stepper-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(34, 41, 47, 0.08);
  margin: 0px;
}
.bs-stepper .bs-stepper-header {
  flex-wrap: wrap;
}
.bs-stepper-header {
  display: flex;
  align-items: center;
}
.bs-stepper .bs-stepper-header .step {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(108, 117, 125);
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  transition: background-color 0.15s ease-out 0s, color 0.15s ease-out 0s;
  background-color: transparent;
  border: none;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-weight: 500;
  color: rgb(186, 191, 199);
  padding: 0.5em 0px;
  border-radius: 0.35rem;
  background-color: rgba(186, 191, 199, 0.12);
}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
  background-color: #263a96;
  box-shadow: 0 8px 25px -8px #263a96;
}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
  color: rgb(255, 255, 255);
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  text-align: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.bs-stepper
  .bs-stepper-header
  .step.active
  .step-trigger
  .bs-stepper-label
  .bs-stepper-title {
  color: #263a96;
}
.bs-stepper
  .bs-stepper-header
  .step
  .step-trigger
  .bs-stepper-label
  .bs-stepper-title {
  display: inherit;
  color: rgb(110, 107, 123);
  font-weight: 600;
  line-height: 1rem;
}
.bs-stepper
  .bs-stepper-header
  .step
  .step-trigger
  .bs-stepper-label
  .bs-stepper-subtitle {
  font-weight: 400;
  font-size: 0.75rem;
  color: rgb(185, 185, 195);
}

.bs-stepper .bs-stepper-header .line {
  flex: 0 1 0%;
  min-width: auto;
  min-height: auto;
  color: rgb(110, 107, 123);
  font-size: 0.9rem;
  background-color: transparent;
  padding: 0px 1.75rem;
}

.bs-stepper-content {
  padding: 1rem;
}
.content-header {
  margin-bottom: 1rem;
}
.content-title {
  font-size: 1.03rem;
  color: #5e5873;
  margin-bottom: 0;
}
.text-muted {
  font-size: 13px;
}

.horizontal-wizard .btn-outline-secondary.disabled {
  border: 1px solid #82868b;
}

/*--------------------------form wizard--------------------------*/

/*--------------------------top breadcrumb--------------------------*/

.content-header .content-header-title {
  font-weight: 500;
  color: #636363;
  padding-right: 1rem;
  border-right: 1px solid #d6dce1;
  font-size: 1.5rem;
  margin-right: 1rem;
}

.breadcrumb {
  margin-bottom: 0;
}

/*--------------------------top breadcrumb--------------------------*/

/*--------------------------breadcrumb--------------------------*/

.breadcrumb-chevron .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "");
  font-family: "Font Awesome 6 Pro";
}

/*--------------------------breadcrumb--------------------------*/

/*--------------------------nav-tabs--------------------------*/
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  color: #6e6b7b;
  border: none;
  position: relative;
  font-weight: 500;
  font-size: 16px;
}

.nav-tabs .nav-link.active:after {
  content: "";
  height: 3px;
  background: linear-gradient(140deg, #263a96, rgba(113, 133, 221, 0.7));
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.nav-tabs .nav-link.active {
  color: #263a96;
}
.nav-tabs .nav-link.disabled {
  color: #b9b9c3;
}

/*Tabs with icons*/

.nav-pills .nav-link {
  color: #6e6b7b;
  border: none;
  position: relative;
  font-weight: 500;
  font-size: 16px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #6e6b7b;
  background-color: transparent;
}

.nav-pills .nav-link.active:after {
  content: "";
  height: 3px;
  background: linear-gradient(140deg, #263a96, rgba(113, 133, 221, 0.7));
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.nav-pills .nav-link.active {
  color: #263a96;
}
.nav-pills .nav-link.disabled {
  color: #b9b9c3;
}

.nav-pills .pills-tab-text {
  margin-left: 10px;
}

/*Tabs with icons*/

/*--------------------------nav-tabs--------------------------*/

.app-content {
  margin-left: 270px;
  position: relative;
}

.content-wrapper {
  padding: calc(2.2rem - 0.4rem) 2.2rem 0;
  margin-top: 5.4rem;
  min-height: calc(100vh - 9.75rem);
}

/*login-basic start*/
.auth-wrapper.auth-basic {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  /* min-height: calc(var(--vh, 1vh)*100); */
  width: 100%;
}
.auth-wrapper.auth-basic .auth-inner {
  max-width: 450px;
}
.auth-wrapper .auth-inner {
  width: 100%;
}
.card {
  box-shadow: 0 4px 24px 0 rgb(34, 41, 47, 0.1);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
}
.no-box {
  box-shadow: none !important;
}
.login-logo {
  display: flex;
  justify-content: center;
  margin: 1rem 0 1rem;
}
.card-title {
  font-weight: 500;
  font-size: 1.1rem;
}
.card-text {
  font-size: 15px;
  line-height: 19px;
}

.create-login-account-bottom {
  font-size: 15px;
  font-weight: 400;
}
.create-login-account-bottom a {
  margin-left: 5px;
}

/*login-basic end*/

/* calender tooltip */
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -40px !important;
}

/*form*/
.form-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  font-size: 0.857rem;
  color: #5e5873;
}

.form-control {
  /* font-size: 0.89rem;
  padding: 0.571rem 1rem; */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  /* border-radius: 0.357rem;
  font-weight: 400; */
}
.form-control:focus {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  border-color: #263a96;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}
/*form*/

.card {
  margin-bottom: 2rem;
}
.card-header {
  padding: 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(34, 41, 47, 0.125);
}
.card .card-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card-header:first-child {
  border-radius: 0.428rem 0.428rem 0 0;
}
.card .card-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.card-body {
  padding: 0 1.5rem 1.5rem;
}

.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.btn-15 {
  margin-right: 15px;
  margin-top: 15px;
}

@media only screen and (max-width: 1200px) {
  .sidenav-overlay {
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 9;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .app-content {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1260px) {
  .bs-stepper .bs-stepper-header .line {
    padding: 0px 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .bs-stepper .bs-stepper-header .line {
    display: none;
  }
  .bs-stepper .bs-stepper-header {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.error {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  font-size: 12px;
}

/*--------------------------Light Badges--------------------------*/

/* badge  */
.badge {
  line-height: unset;
}

.badge.bg-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #263a96;
}

.badge.bg-light-secondary {
  background-color: rgba(130, 134, 139, 0.12);
  color: #82868b;
}

.badge.bg-light-success {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
}

.badge.bg-light-danger {
  color: #ea5455;
  background-color: rgba(234, 84, 85, 0.12);
}

.badge.bg-light-warning {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}

.badge.bg-light-info {
  background-color: rgba(0, 207, 232, 0.12);
  color: #00cfe8;
}

/*--------------------------Light Badges--------------------------*/
.delete-compny {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
.delete-compny-inn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-compnny-data {
  padding: 30px;
  background: #fff;
  border-radius: 20px;
}
.delete-compnny-data h2 {
  margin: 0 0 20px 0;
  font-size: 26px;
  font-weight: 600;
}

.obj_fit_img {
  object-fit: contain !important;
}

.btn-fixed {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.btn-fixed .btn-close {
  background-size: 10px;
  opacity: 0.3;
}

.btn-fixed button:focus:not(:focus-visible) {
  box-shadow: none;
}
.cursor-pointer {
  cursor: pointer;
}

.img-checkpoint {
  height: 53px;
  width: 53px;
  object-fit: cover;
}
.b-radius-10px {
  border-radius: 10px;
}
@media only screen and (max-width: 575px) {
  .content-header .content-header-title {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 575px) {
  .companies-section .btn-danger {
    min-width: 165px;
  }
}
/*--------------------------Company profile view page--------------------------*/
.contract-company-profile .card-body {
  padding: 1.5rem;
}
.contract-company-view .avatar {
  height: 110px;
  width: 110px;
}
.contract-company-view .avatar .avatar-content {
  width: 100%;
  height: 100%;
  font-size: calc(48px);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-figures .badge {
  width: 37px;
  height: 37px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.company-figures .complete-texts {
  margin-left: 10px;
}
.company-figures i {
  font-size: 18px;
}
.company-figures .complete-texts small {
  font-size: 0.75rem;
}
.company-figures .complete-texts h4 {
  font-size: 1rem;
}
.company-details .info-field .left-field {
  font-size: 15px;
  font-weight: 600;
  margin-right: 6px;
}
.company-details .right-field {
  font-size: 15px;
}
.company-details .info-field .right-field.badge {
  font-size: 12px;
}
.border-primary {
  border: 1px solid #263a96 !important;
}
.comapny-plan-card ul {
  list-style-position: inside;
}
.comapny-plan-card .plan-price {
  font-weight: 600;
  line-height: 40px;
}
.contract-company-view .avatar.bg-light-primary {
  color: #263a96;
}
.contract-company-view .avatar.bg-light-primary {
  background: rgba(38, 58, 150, 0.12);
}
.company-details-head {
  font-size: 1.2rem;
}
.obj-fit-cover {
  object-fit: cover;
}
.company-details .info-field {
  display: flex;
  justify-content: space-between;
}
.weekly-collapse .collapse-row {
  border-radius: 5px;
}
.accordion-button {
  padding: 6px;
  padding-right: 15px;
}
.action-button-icon i {
  font-size: 16px;
  background: white;
  width: 30px;
  height: 30px;
  border: 1px solid red;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordion-button::after {
  background-size: 16px;
  height: 1rem;
  width: 1rem;
}
/*--------------------------Company profile view page--------------------------*/
/* Leave View */
.leave-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 51%;
}
/* Leave View End*/
/* Dashboard View */
.card.dashboard-card {
  margin-bottom: calc(var(--bs-gutter-x));
}
.dashboard-card .dashboard-card-body {
  padding: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
}
.dashboard-card-body .dashboard-card-header {
  margin-bottom: 0.25rem !important;
  font-size: 1.714rem;
}
.dashboard-card-body .dashboard-b-avatart {
  width: 45px;
  height: 45px;
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0;
  font-size: 0.857rem;
  font-weight: 600;
  text-align: center;
}
.b-avatar-custom {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-mask-image: radial-gradient(#fff, #000);
  mask-image: radial-gradient(#fff, #000);
}
/* -------------------------- */
/* Css for the inner table */
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"] {
  position: relative;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  > :not(div[role="gridcell"]) {
  position: absolute;
  right: 0;
  z-index: 11;
  height: 100%;
  top: 0;
}
/* .my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]:first-child
  div[role="row"]
  :not(div[data-column-id]) {
  display: none;
} */
.my-tabel-main-one .sc-fqkvVR.sc-fHjqPf {
  display: none;
}

.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"] {
  background-color: #fff;
  font-size: 14px;
  /* font-weight: 600; */
  color: #000;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  + :not(div[role="row"])
  div[role="row"] {
  background-color: #424242;
}
.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  > :not(div[role="gridcell"])
  button
  svg {
  fill: #000 !important;
}
/* Css for the inner table End*/
/*--------------------------User Timeline--------------------------*/
.timeline {
  padding-left: 0.1rem;
}

.timeline .timeline-item {
  padding-left: 2.5rem;
  border-left: 1px solid #ebe9f1;
  position: relative;
  list-style: none;
}
.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content: "";
  height: 20px;
  width: 20px;
  display: block;
  position: absolute;
  top: -0.285rem;
  border-radius: 50%;
  left: -0.25rem;
}
.timeline .timeline-item .timeline-point.timeline-point-indicator {
  top: 0.07rem;
  height: 12px;
  width: 12px;
}
.timeline .timeline-item .timeline-point.timeline-point-indicator {
  left: -0.412rem;
}
.timeline .timeline-item .timeline-point {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  background: rgba(115, 103, 240, 0.12);
}
.timeline .timeline-item .timeline-point.timeline-point-indicator {
  background-color: #7367f0;
}
.timeline
  .timeline-item
  .timeline-point.timeline-point-warning.timeline-point-indicator:before {
  background: rgba(255, 159, 67, 0.12);
}
.timeline
  .timeline-item
  .timeline-point.timeline-point-warning.timeline-point-indicator {
  background-color: #ff9f43;
}
.timeline
  .timeline-item
  .timeline-point.timeline-point-info.timeline-point-indicator:before {
  background: rgba(0, 207, 232, 0.12);
}
.timeline
  .timeline-item
  .timeline-point.timeline-point-info.timeline-point-indicator {
  background-color: #00cfe8;
}
.timeline
  .timeline-item
  .timeline-point.timeline-point-danger.timeline-point-indicator:before {
  background: rgba(234, 84, 85, 0.12);
}
.timeline
  .timeline-item
  .timeline-point.timeline-point-danger.timeline-point-indicator {
  background-color: #ea5455;
}

.timeline-event-time {
  color: #b9b9c3;
}
.timeline .timeline-item:not(:last-child) {
  padding-bottom: 1.8rem;
}

.timeline ul li::marker {
  content: "";
}
.timeline ul li > div {
  align-items: center;
}
.timeline ul li > div .h6 {
  margin-bottom: 0;
}

.my-tabel-main .card {
  width: 98%;
  margin: 20px auto 20px;
  border: 1px solid #7184dd;
  box-shadow: none;
}
.timeline-event ul li {
  margin-bottom: 3px;
  border-top: 1px solid #7184dd;
}
.timeline-event .h6 {
  font-weight: 600;
}
/* -------------------------- */
/*--------------------------User Timeline--------------------------*/

/* CK Editor */
.ck.ck-editor {
  width: 100% !important;
}
.ck.ck-content {
  height: 190px;
}
.title-editor-ck {
  padding: 0;
  margin-bottom: 1%;
  font-size: 18px;
  font-weight: 700;
}
.custom-export-popup {
  position: fixed;
  transform: unset !important;
  background-color: #28c76f;
}
.z-adjust-for-dropdown .rdt_TableHeader {
  overflow: visible !important;
}

.z-adjust-for-dropdown .rdt_TableHeader .sc-fnykZs {
  display: none !important;
}
.star-rating {
  direction: rtl;
  display: inline-block;
  margin-bottom: 1%;
  cursor: default;
}
.star-rating input[type="radio"] {
  display: none;
}
.star-rating label {
  color: #bbb;
  font-size: 2rem;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.star-rating label:hover,
label:hover ~ label,
input[type="radio"]:checked ~ label {
  color: #f2b600;
}
.static-rating label:hover,
.static-rating label:hover ~ label {
  color: #bbb !important;
}
.w-fit {
  width: fit-content;
}

.terms-condition {
  overflow-x: hidden;
  overflow-y: hidden;
}
.terms-condition .modal-body {
  overflow: scroll;
  height: calc(100vh - 130px);
}

.terms-condition .modal-body::-webkit-scrollbar-thumb {
  background-color: #ebebeb;
  border-radius: 20px;
}

.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  > :not(div[role="gridcell"])
  button[disabled]
  svg {
  fill: #fff !important;
}

.my-tabel-main
  div[role="table"]
  > div[role="rowgroup"]
  + div[role="rowgroup"]
  > div[role="row"]
  > :not(div[role="gridcell"])
  button[disabled] {
  cursor: auto;
}
.my-special-class {
  padding-left: 20px;
}

/* daily table modal  */
.daily-table-modal .modal-body {
  overflow-y: auto;
  height: calc(100vh - 110px);
}
.export-loader .spinner-border,
.export-loader .spinner-grow {
  height: 14px;
  width: 14px;
  --bs-spinner-border-width: 0.1em;
}
